import React, { useState } from 'react';
import { submitFeedback } from '../general/functions';
import './Feedback.css';

const Feedback = ({ context }) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [state, setState] = useState('initial');
    const [buttonText, setButtonText] = useState('Submit Feedbac');

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (state === 'initial') {
            setIsSubmitting(true);

            try {
                await submitFeedback(feedback, 'email', 'tbd', context);
                console.log('Feedback submitted:', feedback);
                setIsSubmitted(true); // Indicate feedback has been submitted
            } catch (error) {
                console.error('Feedback submission failed:', error);
            } finally {
                setIsSubmitting(false);
                setState('submitted');
                setButtonText('Edit')
            }

        } else if (state === 'submitted') {
            setIsSubmitted(false);
            setState('editing');
            setButtonText('Submit')
        } else if (state === 'editing') {
            setIsSubmitting(true);

            try {
                await submitFeedback(feedback, 'email', 'tbd', context);
                console.log('Feedback submitted:', feedback);
                setIsSubmitted(true); // Indicate feedback has been submitted
            } catch (error) {
                console.error('Feedback submission failed:', error);
            } finally {
                setIsSubmitting(false);
                setState('submitted');
                setButtonText('Edit')
            }

        }



    };

    const handleEdit = () => {
        setIsSubmitted(false); // Enable form for editing
    };

    return (
        <div className="feedback-form-container">
            <form onSubmit={handleSubmit} className="feedback-form">
                <div className="form-group">
                    <textarea
                        id="feedback"
                        className="form-textarea"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Episode Feedback"
                        required
                        disabled={isSubmitting || isSubmitted}
                    />
                </div>
                <button type="submit" className="buttonFlat button" disabled={isSubmitting} onClick={isSubmitted ? handleEdit : null}>
                    {isSubmitting ? 'Submitting...' : isSubmitted ? 'Submit' : 'Submit'}
                </button>
            </form>
        </div>
    );
};

export default Feedback;
